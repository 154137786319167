import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Slider } from './slider';


export const MainSlider = () => {
  /*
  const { allStrapiAssets: { carouselImages } } = useStaticQuery(graphql`
    query {
      allStrapiAssets(filter: {category: {eq: "carousel"}}) {
        carouselImages {
          strapiId
          type
          url
          sequence
          created_at
          updated_at
        }
      }
    }`);
    */

  const { allCloudinaryMedia: { nodes: allImages } } = useStaticQuery(graphql`
    query CloudinaryImages {
      allCloudinaryMedia {
        nodes {
          resource_type
          secure_url
          public_id
        }
      }
    }`
  );

  // filter carousel assets only
  const carouselImages = allImages.filter(n => n.public_id.indexOf('carousel') >= 0);

  return (
    <Slider images={ carouselImages } />
  );
};
