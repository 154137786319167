import React from 'react';
import PropTypes from 'prop-types';
import { useKeenSlider } from 'keen-slider/react';
import {
  StyledCarousel,
  StyledTextWrapper,
  StyledHeading,
  StyledSubheading,
  StyledImage,
  StyledSlideWrapper,
} from './styled';
import 'keen-slider/keen-slider.min.css';


const CAROUSEL_TIMER = 6000;


export const Slider = ({ images }) => {
  const [opacities, setOpacities] = React.useState([]);
  const [pause, setPause] = React.useState(false);

  const timer = React.useRef();

  const [sliderRef, slider] = useKeenSlider({
    slides: images.length,
    loop: true,
    duration: CAROUSEL_TIMER,
    move(s) {
      const newOpacities = s.details().positions.map(slide => slide.portion);
      setOpacities(newOpacities);
    },
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, CAROUSEL_TIMER);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <StyledCarousel
      ref={ sliderRef }
    >
      {
        images.map((node, idx) => (
          <StyledSlideWrapper
            key={ node.public_id }
            style={{ opacity: opacities[idx] }}
          >
            <StyledImage
              src={ node.secure_url }
              alt='BoxStudio Carousel Image'
            />
            <StyledTextWrapper>
              <StyledHeading>
                BOXSTUDIO 3D
              </StyledHeading>
              <StyledSubheading>
                We specialize in unbuilt architecture visualization
              </StyledSubheading>
            </StyledTextWrapper>
          </StyledSlideWrapper>
        ))
      }
    </StyledCarousel>
  );
};

Slider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};
