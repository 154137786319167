import React from 'react';

// import { Link } from "gatsby"

import { Layout } from '../components/layout';
import SEO from '../components/seo';
import { MainSlider } from '../components/main-slider';


const IndexPage = () => (
  <Layout
    showHeader={ false }
  >
    <SEO title='Home' />
    <MainSlider />

    { /* <Link to="/page-2/">Go to page 2</Link> <br />*/ }
  </Layout>
);

export default IndexPage;
