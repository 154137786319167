import styled from 'styled-components';


export const StyledCarousel = styled.div`
  height: 87vh;
  overflow: hidden;
  position: relative;
`;

export const StyledSlideWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const StyledImage = styled.img`
  height: auto;
  position: absolute;
  width: 100%;
`;

export const StyledTextWrapper = styled.span`
  pointer-events: none;
  position: relative;
  text-align: center;
  z-index: 100;
`;

export const StyledHeading = styled.h1`
  color: white;
  font-display: swap;
  font-size: 8vw;
  text-shadow: 3px 3px #545560;
  text-transform: uppercase;
`;

export const StyledSubheading = styled.h3`
  color: white;
  font-display: swap;
  font-size: 2vw;
  text-shadow: 2px 2px #545560;
  text-transform: uppercase;
`;
